import React from 'react'
import SEO from '../components/SEO'

import styles from '../utilities/css/pages/About.module.css'
import Layout from '../components/Layout'
import siteInfo from '../utilities/config/siteInfo'
import Bio from '../components/Bio'
import Tags from '../components/Tags'
import Testimonial from '../components/Testimonial'
import AboutProfile from '../Assets/AboutProfile.jpg'
import { Link } from 'gatsby'

export default () => {
  const toolsList = siteInfo.toolsList
  const skillsList = siteInfo.skillsList

  const topicSvg = (
    <svg viewBox="0 0 55 55" className={styles.svg} role="img">
      <path
        d="M45.297,21.946l9.656-5.517L27.477,0.825L0,16.429l9.656,5.517L0,27.429l9.656,5.517L0,38.429l27.477,15.698l27.476-15.698
      l-9.656-5.483l9.656-5.517L45.297,21.946z M27.477,3.125l23.435,13.309l-23.435,13.39L4.041,16.434L27.477,3.125z M11.675,23.099
      l15.802,9.028l15.802-9.028l7.633,4.335l-23.435,13.39L4.041,27.434L11.675,23.099z M50.912,38.434l-23.435,13.39L4.041,38.434
      l7.634-4.335l15.802,9.028l15.802-9.028L50.912,38.434z"
      />
    </svg>
  )

  const toolSvg = (
    <svg viewBox="0 0 512 512" className={styles.svg}>
      <g>
        <g>
          <path d="M501,470.8l-57.3-163.3V41.2H69.4v260.4L11,470.8H501z M40.2,451l45.9-134.5h339.9L472.9,451H40.2z M89.2,61h333.6v234.6    H89.2V61z" />
          <polygon points="146.5,186.1 223.7,219.5 223.7,204.9 162.2,178.9 224.7,153.8 224.7,139.2 146.5,172.6   " />
          <polygon points="233.1,239.3 244.5,239.3 278.9,117.3 267.5,117.3   " />
          <polygon points="287.3,153.8 348.8,178.9 287.3,204.9 287.3,219.5 365.5,186.1 365.5,172.6 287.3,139.2   " />
        </g>
      </g>
    </svg>
  )

  return (
    <Layout>
      <div className={styles.pageAbout}>
        <SEO titleText="About me" />

        <Bio title="A propos de moi">
          <ul className={styles.aboutContact}>
            <li>
              <a href="tel:+33186954135">📞 01 86 95 41 35</a>
            </li>
            <li>
              <a href="mailto:cedric@amorem.com">📧 cedric@amorem.com</a>
            </li>

            <li>
              <a href="skype:cedric.tournier?call">🔷 Appel Skype</a>
            </li>
          </ul>
        </Bio>

        <div className={styles.aboutContent}>
          <div className={styles.aboutContentFirstSection}>
            <div className={styles.aboutContentPic}>
              <img
                src={AboutProfile}
                className={styles.aboutProfile}
                title="Good Skills"
              />
            </div>
            <div className={styles.aboutContentText}>
              <p>
                Après 15 ans à des postes de direction informatique, j'ai créé
                ma startup en 2014 et basculé à fond dans le développement, afin
                de réaliser mes propres projets.
              </p>
              <p>
                Mon domaine de prédilection était la finance avec la réalisation
                d'une plateforme de trading algorithmique. Gérer d'importants
                flux temps réels, définir des interfaces de cache avec la base
                de données, accéder à des api complexes, ... Tout cela force à
                sortir de sa zone de confort, à se former en marche forcée et au
                final utiliser les technos les plus pointues.
              </p>
              <p>
                Aout 2019, je développe avec la stack suivante: - React (avec
                hooks ) - NextJS (Server Side Rendering) - Styled Components -
                NodeJS/Express - GraphQL (et ses subscriptions) / Apollo -
                Flutter / Dart
              </p>
              <p>
                Eat your own dog food... Pour financer les temps de
                développement et obtenir des revenus passifs récurrents, j'ai
                lancé en propre plusieurs sites web d'affiliation, en les
                soumettant aux mêmes exigences techniques que j'avais dans le
                trading : - tout y est automatisé à outrance (auto posts sur
                twitter/reddit/wordpress, alimentation automatique des produits
                affiliés par les utilisateurs grace à une chrome extension) -
                optimisé pour les conversions (SEO : RichSnippets, sitemap
                automatique + metadonnées développé from scratch)
              </p>
              <p>
                Ce qui me passionne ce sont les histoires d'entrepreneurs, ceux
                qui ont une vision de leur marché et de leur produit et
                souhaitent réaliser leur POC ou leur produit final avec une
                techno adaptée, qui va scaler sans difficulté, qui va faire la
                différence lors d'un pitch, qui va ouvrir des possibilités
                plutôt que d'en refermer. Discutons de vive voix de votre projet
                et de sa potentielle réalisation par mes soins. Mes pré-requis
                sont le travail en remote et être en direct avec le client (pas
                d'intermédiaire).
              </p>

              <br />
            </div>
          </div>

          {/* <h3 className={styles.sectionTitle}>What I am good at</h3>
          <img src={AboutSkills} className={styles.aboutSkills} title="Good Skills"/> */}
          <h4>{toolSvg} Tools</h4>
          <Tags tags={toolsList} />
          <h4>{topicSvg} Skills</h4>
          <Tags tags={skillsList} />

          {/* <h3 className={styles.sectionTitle}>What people say about me</h3>
          <p>
            When I'm not in front of a computer screen, I spend my time
            paragliding, rock climbing. I also love capturing moments and
            places, through my lens, while exploring the world.
          </p>
          <PhotosGrid>
            Photo
            <br /> Grid
          </PhotosGrid> */}

          {/* <h3 className={styles.sectionTitle}>What people say about me</h3>
          <Testimonial/>

          <p>I am exploring for an opportunity to create an intuitive, elegant and a cutting-edge user experience for a promising product, a motivating mentor and an innovating organization. Feel free to reach out by sending me an <a href="mailto:maitrik.dev@gmail.com?subject=#LetsMakeFuture&body=Let's make beautiful things together.">Email</a> or using one of the links at the bottom of the page.</p> */}
        </div>
      </div>
    </Layout>
  )
}
